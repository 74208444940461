import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Close } from '@material-ui/icons';
import SettingsInputCompositeOutlinedIcon from '@mui/icons-material/SettingsInputCompositeOutlined';
import { useRef, useState } from 'react';
import './SideBarCharts/sidebarcharts.css'
import Loader from './Loader';
import axios from 'axios';
import { CgWebsite } from 'react-icons/cg'
import { VscFilePdf } from 'react-icons/vsc'
import { useData } from '../DataContext';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        // backgroundColor: '#111111',
        // border: '2px solid #000',
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    headTrainings: {
        borderBottom: '1px solid rgb(123, 114, 101)',
        padding: '10px'
    }
}));

const Trainings = ({ serverUrl }) => {

    
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const SCRAP_URL = "https://aidemo.infusai.com:8503";
    const inputRef = useRef(null);
    const { setScrapUrl, themeChanger } = useData();



    const addScrapUrl = () => {
        console.log(url);
        setIsLoading(true);
        axios.post(`${SCRAP_URL}/ReadURL`, { url })
            .then(res => {
                console.log(res);
                setScrapUrl(url);
                setIsLoading(false);
                setOpen(false);
                alert("URL scrapped successfully")
            })
            .catch(err => {
                setIsLoading(false);
                alert(err);
            })
        setUrl('');
    }

    //Train the model
    const handleSelectedFile = () => {
        const file = inputRef.current.files[0];
        console.log(file);
        const form = new FormData();
        form.append('file', file);
        let settings = {};

        if (file.type === "application/pdf") {
            settings = {
                url: `${serverUrl}/uploadFile`,
                method: 'POST',
                timeout: 0,
                processData: false,
                mimeType: 'multipart/form-data',
                contentType: false,
                data: form
            };
            uploadDocApiCall(settings);
        } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "text/csv" || file.type === "application/vnd.ms-excel") {
            settings = {
                url: `https://aidemo.infusai.com:9001/uploadFile`,
                method: 'POST',
                timeout: 0,
                processData: false,
                mimeType: 'multipart/form-data',
                contentType: false,
                data: form
            }
            console.log('Inside excel')
            uploadDocApiCall(settings);
        } else {
            alert("Type not supported");
        }
    };

    const uploadDocApiCall = (settings) => {
        setIsLoading(true);
        axios(settings)
            .then(response => {
                setIsLoading(false);
                alert("Uploaded Sucessfully");
                console.log(response)
            })
            .catch(error => {
                setIsLoading(false);
                alert("Uploading Failed");
                console.log(error)
            });
    }

    return (
        <>
            <li>
                <span onClick={() => setOpen(true)} className='download_pdf'>
                    <SettingsInputCompositeOutlinedIcon />
                    <span className='menu-text'>Train the model</span>
                </span>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={() => setOpen(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper} style={{ background: themeChanger === 'dark-theme' ? '#111111' : 'rgb(196, 204, 208)' }}>
                            <Close onClick={() => setOpen(false)} style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }} className='new-chat-close-btn' />
                            <div className="modal-header" style={{width:'100%'}}>
                                <h4 className="modal-title" style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }} id="transition-modal-title">Train the model</h4>
                            </div>
                            <div className="modal-body">
                                {isLoading && <Loader />}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table " style={{ color: themeChanger ? 'white' : '#45494e' }}>
                                                <tbody>
                                                    <tr >
                                                        <td className="font-14" style={{ border: 'none' }}>
                                                            <div className={classes.headTrainings}>
                                                                <VscFilePdf className='icon-download' />
                                                                <span className='menu-text' style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }}>{" "}Train Documents</span>
                                                            </div>
                                                            <br />
                                                            <span>
                                                                <input ref={inputRef}

                                                                    type="file"
                                                                    id="fileInput"
                                                                    onChange={handleSelectedFile} />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <br />
                                                    <br />

                                                    <tr >
                                                        <td className="font-14">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className={classes.headTrainings}>
                                                                        <CgWebsite className='icon-download' />
                                                                        <span className='menu-text' style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }}>{" "}Train with a website url</span>
                                                                    </div>
                                                                    <br />
                                                                    <input className="form-control input-new-chat" style={{ border: 'none', background: themeChanger !== 'dark-theme' && 'white' }} placeholder="Add Web Scrapping Url" type="text" name="category-name" value={url} onChange={e => setUrl(e.target.value)} />
                                                                    <div className="footer-modal">
                                                                        <button type="button" className="btn btn-primary waves-effect waves-light save-category btn-sm" onClick={addScrapUrl}>Add URL</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </li>
        </>
    )
}

export default Trainings
