
// import React from "react";

// class RulesAndRegulations extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       heading: [],
//       data: [],
//     };
//   }

//   componentDidMount() {
//     this.parseTableString(this.props.tableString);
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.tableString !== prevProps.tableString) {
//       this.parseTableString(this.props.tableString);
//     }
//   }

//   // parseTableString = (tableString) => {
//   //   const rows = tableString.split('\n').map(row => row.trim().split('|').filter(cell => cell.trim() !== ''));
//   //   // Filter out rows containing only hyphens
//   //   const filteredRows = rows.filter(row => !row.every(cell => /^(-+|--)$/.test(cell.trim())));
//   //   // Skip the first row
//   //   const dataRows = filteredRows.slice(1,-1);
//   //   // const dataRowsHead = filteredRows.slice(2,-1);
//   //   const heading = filteredRows[1].map(header => header.trim());
//   //   const data = dataRows.map(row => {
//   //     const rowData = {};
//   //     row.forEach((cell, index) => {
//   //       rowData[heading[index]] = cell.trim();
//   //     });
//   //     return rowData;
//   //   });
  
//   //   this.setState({ heading, data });
//   // }

//   parseTableString = (tableString) => {
//     const rows = tableString.split('\n').map(row => row.trim().split('|').filter(cell => cell.trim() !== ''));
//     // Filter out rows containing only hyphens
//     const filteredRows = rows.filter(row => !row.every(cell => /^(-+|--)$/.test(cell.trim())));
//     // Skip the first and second rows
//     const dataRows = filteredRows.slice(1, -1);
//     const heading = filteredRows[1].map(header => header.trim());
//     const data = dataRows.map(row => {
//       const rowData = {};
//       row.forEach((cell, index) => {
//         rowData[heading[index]] = cell.trim();
//       });
//       return rowData;
//     });
  
//     this.setState({ heading, data });
//   }
  
  

//   render() {
//     const { data } = this.state;

//     if (data.length === 0) {
//       return <div>Loading...</div>;
//     }

//     return (
//       <div
//         style={{
//           padding: "20px",
//           background: "#f8f8f8",
//           border: "1px solid #ddd",
//           borderRadius: "5px",
//         }}
//       >
//         <h2 style={{ color: "green", marginBottom: "20px" }}>
//           Rules and Regulations
//         </h2>
//         <table
//           style={{
//             width: "100%",
//             borderCollapse: "collapse",
//             marginBottom: "20px",
//           }}
//         >
//           <thead>
//             <tr
//               style={{
//                 backgroundColor: "#f2f2f2",
//                 color: "red",
//                 fontWeight: "bold",
//                 textAlign: "center",
//               }}
//             >
//               {Object.values(data[0]).map((header, index) => (
//                 <th
//                   key={index}
//                   style={{
//                     border: "1px solid #ddd",
//                     padding: "12px",
//                     whiteSpace: "nowrap",
//                   }}
//                 >
//                   {header}
//                 </th>
//               ))}
//             </tr>
//           </thead>

//           <tbody>
//             {data.map((item, index) => (
//               <tr key={index}>
//                 {Object.keys(item).map((key, idx) => (
//                   <td
//                     key={idx}
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "8px",
//                       color: "red",
//                       textAlign: "center",
//                     }}
//                   >
//                     {item[key]}
//                   </td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default RulesAndRegulations;



import React from "react";

class RulesAndRegulations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: [],
      data: [],
    };
  }

  componentDidMount() {
    this.parseTableString(this.props.tableString);
  }

  componentDidUpdate(prevProps) {
    if (this.props.tableString !== prevProps.tableString) {
      this.parseTableString(this.props.tableString);
    }
  }

  // parseTableString = (tableString) => {
  //   const rows = tableString.split('\n').map(row => row.trim().split('|').filter(cell => cell.trim() !== ''));
  //   // Filter out rows containing only hyphens
  //   const filteredRows = rows.filter(row => !row.every(cell => /^(-+|--)$/.test(cell.trim())));
  //   // Extract the first row as table headers and the rest as table data
  //   const heading = filteredRows[0].slice(1,-1).map(header => header.trim());
  //   const data = filteredRows.slice(2,-1).map(row => {
  //     const rowData = {};
  //     row.forEach((cell, index) => {
  //       rowData[heading[index]] = cell.trim();
  //     });
  //     return rowData;
  //   });

  //   this.setState({ heading, data });
  // }


    parseTableString = (tableString) => {
    const rows = tableString.split('\n').map(row => row.trim().split('|').filter(cell => cell.trim() !== ''));
    // Filter out rows containing only hyphens
    const filteredRows = rows.filter(row => !row.every(cell => /^(-+|--)$/.test(cell.trim())));
    // Skip the first and second rows
    const dataRows = filteredRows.slice(2, -1);
    const heading = filteredRows[1].map(header => header.trim());
    const data = dataRows.map(row => {
      const rowData = {};
      row.forEach((cell, index) => {
        rowData[heading[index]] = cell.trim();
      });
      return rowData;
    });
    // Extract the table heading from the table string
    const tableHeading = tableString.split('\n')[0].trim();
  
    this.setState({ heading, data,tableHeading });
  }

  render() {
    const { heading, data,tableHeading } = this.state;

    if (data.length === 0) {
      return <div>Loading...</div>;
    }

    return (
      <div
        style={{
          padding: "0px",
          // background: "#f8f8f8",
          // border: "2px solid #ddd",
          // borderRadius: "3px",
        }}
      >
        <p style={{ color: "black", marginBottom: "14px" }}>
        {tableHeading}
        </p>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginBottom: "20px",
          }}
        >
          <thead>
            <tr
              style={{
                // backgroundColor: "#f2f2f2",
                background:"#304a66",
                color: "#ffffff",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {heading.map((header, index) => (
                <th
                  key={index}
                  style={{
                    border: "1px solid #ddd",
                    padding: "12px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(item).map((value, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{
                      border: "2px solid #ddd",
                      padding: "8px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default RulesAndRegulations;

